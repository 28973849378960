<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[grid] -->
     
    <div class="pl-3 text-h6">Actions</div>
    <v-row>
      <template v-for="action in actions">
        <v-col :key="action.name" class="col-12 col-sm-6 col-lg-3 text-center">
          <v-card
            elevation="2"
            outlined
            width="344"
            height="100%"
            :to="action.route"
            :loading="loading"
          >
            <v-card-text>
              <v-icon class="mr-2">{{ action.icon }}</v-icon>
              <span v-if="action.translatableName">{{ $t(action.translatableName) }}</span>
              <span v-else>{{ action.name }}</span>
              <span
                ><Count
                  @loadingCard="loadingCard"
                  :path="action.path"
                  :server="action.server"
              /></span>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
    
    <div class="pl-3 pt-3 text-h6">Modules</div>
    <v-row>
      <template v-for="item in menu">
        <v-col :key="item.name" class="col-12 col-sm-6 col-lg-3 text-center">
          <v-card
            elevation="2"
            outlined
            width="344"
             height="100%"
            :to="item.route"
            :loading="loading"
          >
            <v-card-text>
              <v-icon class="mr-2">{{ item.icon }}</v-icon>
              <span v-if="item.translatableName">{{ $t(item.translatableName) }}</span>
              <span v-else>{{ item.name }}</span>
              <span
                ><Count
                  @loadingCard="loadingCard"
                  :path="item.path"
                  :server="item.server"
              /></span>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-row> </v-row>

    <!-- EOC -->
  </v-container>
</template>

<script>
import menu from "@/datasets/menu";
import { mapState } from "vuex";
import Count from "@/components/Moderator/Dashboard/Count";
export default {
  components: {
    //
    Count,
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    loading: true,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[grid]
    menu: menu.Moderator,
    actions: [
      {
        name: "Pending Sponsored",
        translatableName: null,
        icon: "mdi-account-cash",
        route: { name: "PageModeratorSponsoredStudentBrowse" },
        path: "pendingSponsoredStudent",
        server: "sso",
      },
      {
      name: 'Pending Drawings',
      translatableName:null,
      icon: 'mdi-brush',
      route: { name: 'PageModeratorThemeBrowse' },
      path: 'pendingWorkshopDrawing',
      divider: false,
      server:'game'
    },
    ],
    //EOC
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "PageModeratorDashboard" },
      exact: true,
    });
    //EOC
  },
  mounted() {
    //this.menu = this.menu.concat(menu.Moderator);
  },
  methods: {
    //
    loadingCard() {
      this.loading = false;
    },
  },
};
</script>